body{
	padding: 0;
	margin:0;
	font-family: 'Grandstander-clean';
	font-size: 16px;
	color: #000;
	height: 100%;
	width: 100%;
	background: #bdebfc;
}

a, a:hover, a:focus {
	text-decoration: none;
	outline: none !important;
	color: #000;
}

ul {
	padding: 0;
	margin: 0;
	list-style: none;
}

h1, h2, h3, h4, h5 {
	margin: 0;
}

h1{
	font-family: 'AnjaElianeaccent002';
	font-size: 40px;
	color: #048459;

}

input, button, a {
	outline: none !important;
}

/* ==== GENERAL ====*/
.pt30{
	padding-top:30px !important;
}
.ptb20{
	padding: 20px 0;
}
.ptb40{
	padding: 40px 0;
}
.mt10{
	margin-top: 10px;
}
.mt20{
	margin-top: 20px;
}
.mtb70{
	margin: 70px 0;
}


/* ====  HOME ====*/

.container{
	width: 100%;
	max-width: 1170px;
	overflow: hidden;
	margin: 0 auto;
}


.bg-top{
	width: 100%;
	height: 170px;
	background: url('../img/cloud-top.png') no-repeat bottom center;
	background-size: cover;
	position: relative;

	> .pt20{
		padding-top:20px !important;
	}
	
	.logo-left{
		width: 20%;
		height: 100%;
		float: left;
		img{
			width: 75%;
		}
	}
	.top-nav{
		width: 60%;
		height: 100%;
		float: left;
		text-align: center;
		
		ul{
			padding: 0;
			margin:0;

			li{
				display: inline-block;
				margin: 0 36px;
				position: relative;

				&:hover{
					border-bottom: 2px solid #000;
				}

				a{
					font-size: 20px;
				}
			}

			.home{
				&:before{
					content: " ";
					background: url('../img/home-icon.png') no-repeat center;
					position: absolute;
					width: 23px;
					height: 32px;
					left: -28px;
					top: 0px;
				}
			}
			.regis{
				&:before{
					content: " ";
					background: url('../img/registrasi-icon.png') no-repeat center;
					position: absolute;
					width: 28px;
					height: 32px;
					left: -33px;
					top: 0px;
				}
			}
			.lesson{
				&:before{
					content: " ";
					background: url('../img/pelajaran-icon.png') no-repeat center;
					position: absolute;
					width: 27px;
					height: 32px;
					left: -30px;
					top: 0px;
				}
			}
		}
	}
	.logo-right{
		width: 20%;
		height: 100%;
		float: right;

		img{
			float: right;
			width: 70%;
			margin-top: 10px;
		}
	}
}



.home-content{
	width: 100%;

	.top-content{

		a{
			display: table;
		}
		
		.top-row{
			img{
				width: 90%;
			}
		}
		.bird-box{
			height: 180px;
			position: relative;
			padding: 20px 0;

			
			.yellow-left, .blue-left{
				animation-name: bird_left_wing_animation;
				animation-duration: 2.5s;
				animation-iteration-count: infinite;
				animation-timing-function: ease-out;
				-webkit-animation-name: bird_left_wing_animation;
				-webkit-animation-duration: 2.5s;
				-webkit-animation-iteration-count: infinite;
				-webkit-animation-timing-function: ease-out;
			}

			.yellow-right, .blue-right{
				animation-name: bird_right_wing_animation;
				animation-duration: 2.5s;
				animation-iteration-count: infinite;
				animation-timing-function: ease-out;
				-webkit-animation-name: bird_right_wing_animation;
				-webkit-animation-duration: 2.5s;
				-webkit-animation-iteration-count: infinite;
				-webkit-animation-timing-function: ease-out;
			}

			.yellow-bird{
				position: absolute;
				right: 0px;
				width: 55px;
				height: 75px;
				-webkit-transform: rotate(-15deg);
				-ms-transform: rotate(-15deg);
				transform: rotate(-15deg);
				animation-name: bird_body_animation_yellow;
				animation-duration: 2.5s;
				animation-iteration-count: infinite;
				animation-timing-function: ease-out;
				-webkit-animation-name: bird_body_animation_yellow;
				-webkit-animation-duration: 2.5s;
				-webkit-animation-iteration-count: infinite;
				-webkit-animation-timing-function: ease-out;

				.yellow-bird-wrap{
					position: relative;
					width: 100%;
					height: 100%;
					
					.yellow-body{
						position: absolute;
						z-index: 2;
					}
					.yellow-left{
						position: absolute;
						left: -11px;
						top: 25px;
						width: 18px;
						
					}
					.yellow-right{
						position: absolute;
						right: -11px;
						top: 25px;
						width: 18px;
						
					}
				}
			}

			.blue-bird{
				position: absolute;
				left: 75px;
				top: 67px;
				width: 55px;
				height: 75px;
				-webkit-transform: rotate(15deg);
				-ms-transform: rotate(15deg);
				transform: rotate(15deg);
				animation-name: bird_body_animation_blue;
				animation-duration: 2.5s;
				animation-iteration-count: infinite;
				animation-timing-function: ease-out;
				-webkit-animation-name: bird_body_animation_blue;
				-webkit-animation-duration: 2.5s;
				-webkit-animation-iteration-count: infinite;
				-webkit-animation-timing-function: ease-out;

				.blue-bird-wrap{
					position: relative;
					width: 100%;
					height: 100%;
					
					.blue-body{
						position: absolute;
						z-index: 2;
					}
					.blue-left{
						position: absolute;
						left: -11px;
						top: 25px;
						width: 18px;
					}
					.blue-right{
						position: absolute;
						right: -11px;
						top: 25px;
						width: 18px;
					}
				}
			}
		}
		
		.menu-row {
			width: 100%;
			.center-menu-box{
				display: table;
				margin: 0 auto;
			}
			.menu-box{
				display: inline-block;
				margin: 0 42px;
				position: relative;

				.title-menu{
					font-family: 'AnjaElianeaccent002';
					font-size: 32px;
					position: absolute;
					top: 20px;
					left: 0;
					right: 0;
					text-align: center;

					.red-color{
						color: #f26c4f;
					}
					.orange-color{
						color: #f7941d;
					}
					.green-color{
						color: #87be3c;
					}
				}

				.kupu{
					position: absolute;
					right: 45px;
					top: 155px;
					opacity: 0;
					transition: 0.5s all ease;
				}
				.kupu-anim{
					animation-name: kupu-animation;
					animation-duration: 8s;
					animation-iteration-count: infinite;
					animation-timing-function: ease-out;
					-webkit-animation-name: kupu-animation;
					-webkit-animation-duration:8s;
					-webkit-animation-iteration-count: infinite;
					-webkit-animation-timing-function: ease-out;
				}
				.planet{
					position: absolute;
					right: 33px;
					top: 70px;
					opacity: 0;
				}
				.planet-anim{
					animation-name: planet-animation;
					animation-duration: 8s;
					animation-iteration-count: infinite;
					animation-timing-function: ease-out;
					-webkit-animation-name: planet-animation;
					-webkit-animation-duration:8s;
					-webkit-animation-iteration-count: infinite;
					-webkit-animation-timing-function: ease-out;
					
				}
				.talk{
					position: absolute;
					right: 92px;
					top: 70px;
					opacity: 0;
				}
				.talk-anim{
					animation-name: talk-animation;
					animation-duration: 2s;
					animation-iteration-count: infinite;
					animation-timing-function: ease-out;
					-webkit-animation-name: talk-animation;
					-webkit-animation-duration:2s;
					-webkit-animation-iteration-count: infinite;
					-webkit-animation-timing-function: ease-out;
				}

				
			}
			
		}
	}

	.login-btn{
		display: table;
		background: #f18039;
		border-radius: 13px;
		border-bottom: 5px solid #754b23;
		padding: 10px 20px;
		color: #fff;
		font-size: 16px;

		&:active{
			/* background: #db7534;*/
			margin-top: 3px;
			border-bottom: 2px solid #754b23;
		}
	}
}


footer{
	margin-top: 100px;

	.bg-foot{
		width: 100%;
		height: 300px;
		background: url('../img/footer-big.png')  no-repeat top center;
		background-size: cover;
		position: relative;
		z-index: 1;

		.copyright{
			width: 100%;
			position: absolute;
			bottom: 0;

			span{
				position: absolute;
				top: -35px;
				left: 5%;
				text-align: center;
				color: #fff;
				font-weight: 300;
			}
			.volume{
				background: url(../img/on-wht.png) no-repeat center;
				right: 5%;
				width: 340px;
				height: 30px;
				left: inherit;
				cursor: pointer;
				padding-left: 200px;
			}
			.muted{
				background: url(../img/off-wht.png) no-repeat center;
			}

		}
	}
}

#mobile-view{
	display: none;
}

.valign-middle {
	display: table;

	> .columns {
		display: table-cell;
		vertical-align: middle;
	}

	> .columns, > [class*="column"] + [class*="column"]:last-child {
		float: none;
	}
}


/* ==== REGIS ====*/

.regis-section{
	width: 100%;
	/* height: 500px;*/
	padding: 0 100px;
	text-align: center;
	margin-top: 40px;

	.regis-form{
		width: 100%;
		padding: 30px 50px;
		margin-top: 30px;
		text-align: left;
		background: #f7f5ec;
		
		label{
			color:#00a055;
			font-size:22px;
			margin: 20px 0;
		}
		input{
			border: 2px solid #4ec58d;
			color: #999;
			line-height: 45px;
			height: 43px;
			
		}
		input::-webkit-input-placeholder{
			line-height: 30px;
		}

		.wrong{
			border: 3px solid #d04545;
			color: #d04545;
		}
		.valid-notif{
			color: #d04545;
			font-size: 22px;
			line-height: 35px;
			text-align: center;
		}


		.regis-btn{
			width: 100%;
			border-radius: 20px;
			padding: 10px 0;
			background: #40ba7b;
			color:#f4f3ef;
			text-align: center;
			line-height: 25px;
			box-shadow: 3px 4px #20774a;
			font-size: 22px;
			border: none;
			font-family: 'Grandstander-clean';
			cursor: pointer;

			&:hover{
				background: #38a26b;
			}
		}
		#jkelamin{
			border: 2px solid #4ec58d;
			color: #999;
			line-height: 23px;
			height: 43px;
			background: url("../img/select-btn.jpg") no-repeat right -1px center;
		}
		.mtop40{
			margin-top: 40px;
		}

		.mbot20{
			margin-bottom: 20px;
		}
	}
}

/* ==== LOGIN ====*/

.login-section{
	width: 75%;
	padding: 10px 100px;
	text-align: center;
	margin-top: 40px;
	margin: auto;

	.login-form{
		width: 100%;
		padding: 30px 50px;
		margin-top: 30px;
		text-align: left;
		background: #f7f5ec;
		box-shadow: 10px 10px #68b2eb;

		input::-webkit-input-placeholder{
			line-height: 30px;
		}
		
		label{
			color:#00a055;
			font-size:22px;
			margin: 20px 0;
		}
		.pass{
			width: 100%;
			height: 43px;
			background: #fefefe;
			border: 2px solid #4ec58d;
			display: block;
			.input_pass{
				box-shadow: none;
				float: left;
				width: 85%;
				border: transparent;
				color: #999;
				line-height: 45px;
			}
			.lock_pass{
				float: right;
				margin: 5px;
				display: block;
				width: 22px;
				height: 29px;
				background: url('../img/lock-green.png') no-repeat;
				cursor: pointer;

			}
			.unlock_pass{
				background: url('../img/lock-red.png') no-repeat;
			}
		}

		.input_user{
			border: 2px solid #4ec58d;
			color: #999;
			line-height: 45px;
			height: 43px;
		}
		.wrong{
			border: 3px solid #d04545;
			color: #d04545;
		}
		.wrong-notif{
			color: #d04545;
			float: right;
			font-size: 16px;
			line-height: 35px;
		}
		.login-btn{
			width: 100%;
			border-radius: 20px;
			padding: 10px 0;
			background: #40ba7b;
			color: #f4f3ef;
			text-align: center;
			line-height: 25px;
			box-shadow: 3px 4px #20774a;
			font-size: 22px;
			border: none;
			font-family: 'Grandstander-clean';
			cursor: pointer;

			&:hover{
				background: #38a26b;
			}
		}
		.mtop40{
			margin-top: 40px;
		}

		.mbot20{
			margin-bottom: 20px;
		}
	}
}

/* ==== PELAJARAN ====*/

.pelajaran-section{
	width: 80%;
	margin: 0 auto;
	text-align: center;

	.block-bar{
		width: 100%;
		padding: 20px 25px;
		color: #f4f3ef;
		font-family: 'AnjaElianeaccent002';
		font-size: 32px;
		text-align: left;
		border-radius: 15px;
		position: relative;
	}

	.blue-block{
		background: #3f98eb;
		box-shadow: 4px 4px #1860a3;
		&:hover{
			background: #2a76bd;
		}
	}
	.orange-block{
		background: #ebb03f;
		box-shadow: 4px 4px #936714;
		&:hover{
			background: #c39233;
		}
	}
	.green-block{
		background: #51bb40;
		box-shadow: 4px 4px #277d19;
		&:hover{
			background: #238d11;
		}
	}
	.non-active{
		-webkit-filter: grayscale(100%);
		filter: grayscale(100%);
		background: #51bb40;
		box-shadow: 4px 4px #277d19;
	}
	.kelas3{
		position: absolute;
		right: -10px;
		bottom: -5px;
	}
	.kelas4{
		position: absolute;
		right: -6px;
		bottom: -1px;
	}
	.kelas5{
		position: absolute;
		right: -5px;
		bottom: -14px;
	}
}



/* ==== OURSELVES ====*/
.notif-learn{
	color: #d65919;
	text-align: center;
	position: absolute;
	left: 0;
	right: 0;
	margin: 0 auto;
	width: 50%;
	bottom: 40px;
	font-size: 16px;

	a{
		
		/* outline: none !important;*/
		color: #0071a0;

		&:hover{
			text-decoration: underline;
		}
	}
	.purple{
		color: purple;
		&:hover{
			text-decoration: underline;
		}
	}
}
.ourselves-section{
	width: 80%;
	margin: 0 auto;
	text-align: center;

	h4{
		font-family: 'Grandstander-clean';
		font-size: 16px;
		color: #f7941e;
	}
	p{
		font-family: 'Grandstander-clean';
		font-size: 17px;
		color: #00a055;
		margin: 0 auto;
	}
	.sess-box{
		display: table;
		margin: 0 auto;
		position: relative;
		.box-image-game{
			width: 100%;
			height: 100%;
			overflow: hidden;
			
			&.b-blue{
				border: 3px solid #047f99;
				border-radius: 25px;
			}

			&.b-orange{
				border: 3px solid #fb913c;
				border-radius: 25px;
			}

			&.b-green{
				border: 3px solid #0a9918;
				border-radius: 25px;
			}

			img{
				&:hover{
					transition: all 1s ease ;
					transform: scale(1.2) rotate(-10deg);
				}
			}
		}
	}
	.back-btn{
		width: 100%;
		border-radius: 20px;
		padding: 10px 0;
		background: #40ba7b;
		color:#f4f3ef;
		text-align: center;
		line-height: 25px;
		box-shadow: 3px 4px #20774a;
		font-size: 16px;

		&:hover{
			background: #38a26b;
		}
	}
	.locked{
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		margin: auto;
		width: 187px;
		height: 132px;

		.locked-bg{
			background: #000;
			width: 100%;
			height: 100%;
			border-radius: 15px;
			opacity: 0.7;
			position: absolute;
			left: 0;
			z-index: 1;
		}
		.lock{
			z-index: 2;
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			margin: auto;
		}
	}
	
}
#log-popup{
	width: 100%;
	height: 100vh;
	background: rgba(23,25,26,0.7);
	position: fixed;
	top: 0;
	z-index: 10;
	display: none;

	.popup-wrap{
		width: 385px;
		height: 450px;
		margin: auto;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		top: 0;
		z-index: 11;

		.parent-popup{
			width: 100%;
			height: 450px;
			position: relative;

			.box-content-popup{
				padding: 30px 20px;
				position: absolute;
				width: 100%;
				height: 250px;
				background: #f7f6ed;
				bottom: 0;
				.text-content{
					text-align: center;
					font-size: 16px;
					color: #00a651;
					span{
						color: #f7941d;
					}
				}
				.btn-wrap{
					width: 100%;
					height: 50px;
					margin-top: 50px;
					.login-btn{
						float: left;
						display: table;
						padding: 10px 15px;
						width: 45%;
						height: 90%;
						border-radius: 20px;
						background: #40ba7b;
						text-align: center;
						color: #fff;
						box-shadow: 3px 3px #20774a;

						&:hover{
							background: #38a16b;
						}
					}
					.regis-btn{
						float: right;
						display: table;
						padding: 10px 15px;
						width: 45%;
						height: 90%;
						border-radius: 20px;
						background: #e9940d;
						text-align: center;
						color: #fff;
						box-shadow: 3px 3px #6d4910;

						&:hover{
							background: #cf840d;
						}
					}
				}
				.peek-girl{
					position: absolute;
					top: -106px;
					left: 0;
					right: 0;
					margin: auto;
				}
				.close-btn{
					width: 50px;
					height: 50px;
					border-radius: 50%;
					position: absolute;
					top: -24px;
					right: -24px;
					cursor: pointer;
					background:  url('../img/x.png') #754c24 no-repeat center;
				}
			}
		}
	}
}

/* ==== KARTU PELAJAR ====*/

.card-section{
	width: 100%;
	/* height: 500px;*/
	padding: 10px 100px;
	text-align: center;
	margin-top: 40px;

	.card-form{
		width: 100%;
		padding: 30px 50px;
		border-radius: 10px;
		margin-top: 30px;
		text-align: left;
		background: #f7f5ec;
		box-shadow: 5px 5px #cfcfcf;
		position: relative;
		
		h1{
			color: #433e3b;
		}
		label{
			color:#00a055;
			font-size:22px;
			margin: 20px 0;
		}
		input{
			border: 2px solid #4ec58d;
			color: #999;
			line-height: 45px;
			height: 43px;
		}

		.prof-pic{
			width: 100%;
			height: 230px;
			background: #0f0;
		}
		.boy-pic{
			background: url('../img/boy-picture.jpg') no-repeat center;
			width: 100%;
		}
		.girl-pic{
			background: url('../img/girl-picture.jpg') no-repeat center;
			width: 100%;
		}
		.form-logo{
			margin-top: 18px;
		}

		.name-title{
			color: #00a055;
			font-size: 18px;
		}
		.name-txt{
			color: #e98922;
			font-size: 24px;
		}

		.card-btn{
			width: 100%;
			border-radius: 13px;
			padding: 10px 0;
			background: #f18039;
			color:#f4f3ef;
			text-align: center;
			line-height: 25px;
			box-shadow: 3px 4px #754b23;
			font-size: 20px;

			&:hover{
				background: #db7433;
			}
		}

		.flower-card{
			position: absolute;
			top: 30px;
			right: 30px;
		}
		.car-card{
			position: absolute;
			bottom: 30px;
			right: 30px;
		}
		#jkelamin{
			border: 2px solid #4ec58d;
			color: #999;
			line-height: 23px;
			height: 43px;
			background: url("../img/select-btn.jpg") no-repeat right -1px center;
		}
		.mtop40{
			margin-top: 40px;
		}
		.mtop-10{
			margin-top: 10px;
		}
		.mtop-20{
			margin-top: 20px;
		}
		.mbot20{
			margin-bottom: 20px;
		}
	}
}

// ==== TENTANG ====
.about-section{
	width: 850px;
	height: 1200px;
	padding: 0 40px;
	margin: 0 auto;
	margin-top: 40px;
	background: url("../img/paper-bg.jpg") no-repeat center;
	background-size: cover;

	h3{
		font-size: 22px;
		color: #00a055;
		font-family: 'Grandstander-clean';
	}
	.ptop-40{
		padding-top: 40px;
	}
	.mtop-70{
		margin-top: 70px;
	}
	
	.img-left{

	}
	.img-right{
		float: right;
	}
	.text-about{
		font-size: 16px;
		font-family: 'Grandstander-clean';
		color: #000;
		margin-top: 59px;
		line-height: 43px;

		a{
			color: #00aeef;
		}
	}
}

@media screen and (max-width: 959px){

	body{
		position: fixed;
	}
	#mobile-view{
		background: #bdebfc;
		width: 100%;
		height: 100vh;
		position: fixed;
		z-index: 10;
		top: 0;
		display: block;


		.top-bg{
			background: url('../img/cloud-mobile.jpg') no-repeat bottom center;
			background-size: cover;
			width: 100%;
			height: 120px;
			padding: 15px;
			padding-bottom: 0;

			.logo-l{
				width: 50%;
				float: left;
				img{
					width: 90%;
				}
			}
			.logo-r{
				width: 50%;
				float: right;
				img{
					padding-top: 12px;
					float: right;
				}
			}
		}
		.mobile-content{
			width: 100%;
			height: 200px;
			text-align: center;
			font-size: 16px;
			color: #00a055;
			position: relative;
			display: table;

			.mobile-info{
				display: table;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				position: absolute;
				margin: auto;
				width: 100%;
			}
		}

		.bot-bg{
			background: url(../img/bottom-mobile.jpg) no-repeat top center;
			background-size: cover;
			width: 100%;
			height: 180px;
			position: absolute;
			bottom: 0;
		}
	}

}

@media screen and (min-width: 1200px){
	.login-section {
		width: 60%;
	}
}

@media screen and (min-width: 1300px){
	.container{
		max-width: 1300px;
	}
	.ourselves-section{
		width: 72%;

		.sess-box{
			display: table;
			margin: 0 auto;
		}
	}

}

@media screen and (min-width: 1600px){
	footer{
		.bg-foot{
			height: 389px;
		}
	}
}

@media screen and (min-width: 1900px){
	.container{
		max-width: 1550px;
	}
}


@-webkit-keyframes bird_body_animation_blue {
	0% { -webkit-transform: translate3d(0,0px,0) rotate(15deg); }
	25%{  -webkit-transform: translate3d(0,0px,0) rotate(15deg); }
	50%{  -webkit-transform: translate3d(0,-10px,0) rotate(15deg); }
	75%{  -webkit-transform: translate3d(0,0px,0) rotate(15deg); }
	100%{  -webkit-transform: translate3d(0,0px,0) rotate(15deg); }
}
@keyframes bird_body_animation_blue {
	0% {  -webkit-transform: translate3d(0,0px,0) rotate(15deg);  transform: translate3d(0,0px,0) rotate(15deg); }
	25%{  -webkit-transform: translate3d(0,0px,0) rotate(15deg);  transform: translate3d(0,0px,0) rotate(15deg); }
	50%{  -webkit-transform: translate3d(0,-10px,0) rotate(15deg);  transform: translate3d(0,-10px,0) rotate(15deg); }
	75%{  -webkit-transform: translate3d(0,0px,0) rotate(15deg);  transform: translate3d(0,0px,0) rotate(15deg); }
	100%{ -webkit-transform: translate3d(0,0px,0) rotate(15deg); transform: translate3d(0,0px,0) rotate(15deg); }
}

@-webkit-keyframes bird_body_animation_yellow {
	0% { -webkit-transform: translate3d(0,0px,0) rotate(-15deg); }
	25%{  -webkit-transform: translate3d(0,0px,0) rotate(-15deg); }
	50%{  -webkit-transform: translate3d(0,-10px,0) rotate(-15deg); }
	75%{  -webkit-transform: translate3d(0,0px,0) rotate(-15deg); }
	100%{  -webkit-transform: translate3d(0,0px,0) rotate(-15deg); }
}
@keyframes bird_body_animation_yellow {
	0% {  -webkit-transform: translate3d(0,0px,0) rotate(-15deg);  transform: translate3d(0,0px,0) rotate(-15deg); }
	25%{  -webkit-transform: translate3d(0,0px,0) rotate(-15deg);  transform: translate3d(0,0px,0) rotate(-15deg); }
	50%{  -webkit-transform: translate3d(0,-10px,0) rotate(-15deg);  transform: translate3d(0,-10px,0) rotate(-15deg); }
	75%{  -webkit-transform: translate3d(0,0px,0) rotate(-15deg);  transform: translate3d(0,0px,0) rotate(-15deg); }
	100%{ -webkit-transform: translate3d(0,0px,0) rotate(-15deg); transform: translate3d(0,0px,0) rotate(-15deg); }
}

@-webkit-keyframes bird_right_wing_animation {
	0% { -webkit-transform: rotate(10deg); }
	25%{  -webkit-transform: rotate(10deg);}
	35% { -webkit-transform: rotate(-50deg); }
	40% { -webkit-transform: rotate(-10deg); }
	45% { -webkit-transform: rotate(-50deg); }
	50%{ -webkit-transform: rotate(10deg);}
	60% { -webkit-transform: rotate(-50deg); }
	65% { -webkit-transform: rotate(-10deg); }
	70% { -webkit-transform: rotate(-50deg); }
	75%{ -webkit-transform: rotate(10deg);}
	100%{ -webkit-transform: rotate(10deg);}
}
@keyframes bird_right_wing_animation {
	0% { -webkit-transform: rotate(10deg); transform: rotate(10deg); }
	25%{  -webkit-transform: rotate(10deg);  transform: rotate(10deg);}
	35% { -webkit-transform: rotate(-50deg); transform: rotate(-50deg); }
	40% { -webkit-transform: rotate(-10deg); transform: rotate(-10deg); }
	45% { -webkit-transform: rotate(-50deg); transform: rotate(-50deg); }
	50%{ -webkit-transform: rotate(10deg); transform: rotate(10deg);}
	60% { -webkit-transform: rotate(-50deg); transform: rotate(-50deg); }
	65% { -webkit-transform: rotate(-10deg); transform: rotate(-10deg); }
	70% { -webkit-transform: rotate(-50deg); transform: rotate(-50deg); }
	75%{ -webkit-transform: rotate(10deg); transform: rotate(10deg);}
	100%{ -webkit-transform: rotate(10deg); transform: rotate(10deg);}
}
@-webkit-keyframes bird_left_wing_animation {
	0% { -webkit-transform: rotate(-10deg); }
	25%{  -webkit-transform: rotate(-10deg);}
	35% { -webkit-transform: rotate(40deg); }
	40% { -webkit-transform: rotate(10deg); }
	45% { -webkit-transform: rotate(40deg); }
	50%{ -webkit-transform: rotate(10deg);}
	60% { -webkit-transform: rotate(40deg); }
	65% { -webkit-transform: rotate(10deg); }
	70% { -webkit-transform: rotate(40deg); }
	75%{ -webkit-transform: rotate(-10deg);}
	100%{ -webkit-transform: rotate(-10deg);}
}
@keyframes bird_left_wing_animation {
	0% { -webkit-transform: rotate(-10deg); transform: rotate(-10deg); }
	25%{  -webkit-transform: rotate(-10deg);  transform: rotate(-10deg);}
	35% { -webkit-transform: rotate(40deg); transform: rotate(40deg); }
	40% { -webkit-transform: rotate(10deg); transform: rotate(10deg); }
	45% { -webkit-transform: rotate(40deg); transform: rotate(40deg); }
	50%{ -webkit-transform: rotate(10deg); transform: rotate(10deg);}
	60% { -webkit-transform: rotate(40deg); transform: rotate(40deg); }
	65% { -webkit-transform: rotate(10deg); transform: rotate(10deg); }
	70% { -webkit-transform: rotate(40deg); transform: rotate(40deg); }
	75%{ -webkit-transform: rotate(-10deg); transform: rotate(-10deg);}
	100%{ -webkit-transform: rotate(-10deg); transform: rotate(-10deg);}
}

@keyframes kupu-animation{
	0%{
		
		transform: translate3d(10px,10px,0px);
	}
	20%{
		opacity: 1;
		transform: translate3d(-10px,-10px,0px);
	}
	40%{
		transform: translate3d(0px,0px,0px);
	}
	60%{
		transform: translate3d(-10px,-10px,0px);
	}
	80%{
		opacity: 1;
		transform: translate3d(0px,0px,0px);
	}
	100%{
		transform: translate3d(10px,10px,0px);
	}
}

@keyframes planet-animation{
	0%{
		transform: rotate(0deg);
	}
	
	20%{
		opacity: 1;
		transform: rotate(-5deg);
	}

	40%{
		transform: rotate(5deg);
	}

	60%{
		transform: rotate(-5deg);
	}
	
	80%{
		opacity: 1;
		transform: rotate(5deg);
	}
	100%{
		transform: rotate(0deg);
	}
}

@keyframes talk-animation{
	0%  { 
		opacity: 1;
		transform: translate3d(0px,0px,0px);
	}
	30%{
		transform: translate3d(0px,10px,0px);
	}
	50%{
		transform: translate3d(0px,0px,0px);
	}
	70%{
		transform: translate3d(0px,10px,0px);
	}
	100% { 
		opacity: 1;
		transform: translate3d(0px,0px,0px);

	}
}

// tambahan billy
.lupa-orange{
	color: #f7941e;

	&:hover{
		color: #f7941e;
	}
}